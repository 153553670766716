export enum AnalyticsActions {
  DOWNLOADED = "Downloaded",
  SAVED = "Saved",
  STARTED = "Started",
  VIEWED = "Viewed",
}

export enum AnalyticsObjects {
  PAGE = "Page",
  QR_CODE = "QrCode",
  NOTE = "Note",
}
