export const AnalyticsEvents = {
  Page_Viewed: {
    page_type: "string",
  },

  QrCode_Downloaded: {
    number_of_crops: "number",
    number_of_locations: "number",
    note_type: "string",
  },

  Note_Started: {
    branch: "string",
    checklistId: "string",
    cropIds: "object",
    locationIds: "object",
    noteType: "string",
    taskId: "string",
  },

  Note_Saved: {
    branch: "string",
    noteType: "string",
  },
};
