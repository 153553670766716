import { z } from "zod";
import { documentReference, timestamp } from "@/utils/custom-validator-types";
import { CropField } from "@/data/crop-field-options";
import { UserType } from "@/models/user.model";
import { allowedLocationSchema } from "./user.schema";

export enum EnvironmentSetupMethod {
  CONTAINER_FIELD = "containerField",
  GREENHOUSE = "greenhouse",
  OPEN_SOIL = "openSoil",
  POLYTUNNEL = "polytunnel",
  OTHER = "other",
}
export enum YAxisSettingsGlobal {
  DEFAULT = "default",
  BOUND_BY_VALUES = "boundByValues",
}

export enum SensorProvider {
  BLOCKBAX = "blockbax",
  TENTACLES = "tentacles",
}

export enum WateringUnit {
  MIN = "min",
  CC_M2 = "CC / m2",
  L_M2 = "L / m2",
  CC_DRIPPER = "CC / dripper",
}

export enum DrainageUnit {
  L = "L",
  CC = "CC",
  PERCENT = "%",
}

export enum FertilizationBinType {
  AB = "a-b",
  ABC = "a-b-c",
}

export enum NoteWateringItems {
  WATERING_AMOUNT,
  EC,
  DRAINAGE
}

export const weatherSchema = z.object({
  humAvg: z.number().optional(),
  irrSum: z.number().optional(),
  leafSum: z.number().optional(),
  rainAvg: z.number().optional(),
  rainSum: z.number().optional(),
  tavgAvg: z.number().optional(),
  tmaxAvg: z.number().optional(),
  tminAvg: z.number().optional(),
});

export const locationLevel2Schema = z.object({
  id: z.string(),
  name: z.string(),
  surfaceArea: z.number().nullable().optional(),
  dirty: z.boolean().optional(),
});

export const locationLevel1Schema = z.object({
  id: z.string(),
  name: z.string(),
  surfaceArea: z.number().nullable().optional(),
  dirty: z.boolean().optional(),
  locationsLevel2: z.array(locationLevel2Schema).optional(),
});

export const locationsLevel0Schema = z.object({
  id: z.string(),
  geo: z
    .object({
      latitude: z.coerce.number(),
      longitude: z.coerce.number(),
    })
    .optional(),
  name: z.string(),
  address: z.string().optional(),
  surfaceArea: z.number().nullable().optional(),
  weatherData: z.record(weatherSchema),
  locationsLevel1: z.array(locationLevel1Schema).optional(),
});

export const languageSchema = z.object({
  code: z.string().optional(),
  file: z.string().optional(),
  iso: z.string().optional(),
  name: z.string().optional(),
  text: z.string().optional(),
});

export const environmentSchema = z.object({
  setupMethods: z.array(z.nativeEnum(EnvironmentSetupMethod)).optional(),
  type: z.string().optional(),
  notes: z.string().optional(),
  challenges: z.string().optional(),
  certification: z.string().optional(),
});

export const noteChecklistSchema = z.object({
  id: z.string(),
  name: z.string(),
  steps: z.array(z.string()),
  extras: z.array(
    z.object({
      values: z.record(z.any()).optional(),
    }),
  ),
  displayIn: z.string().nullable().optional(),
  customLabel: z
    .object({
      id: z.string(),
      label: z.string(),
    })
    .nullable()
    .optional(),
});

export const companyLabelsSchema = z.object({
  consumptionCrops: z.array(z.string()).optional(),
  ornamentals: z.array(z.string()).optional(),
  cultivationMethod: z.array(z.string()).optional(),
  countries: z.array(z.string()).optional(),
});

export const climateBoxesSettingsSchema = z.record(
  z.object({
    startDate: timestamp,
    endDate: timestamp.nullable(),
    locations: z.array(z.string()),
  }),
);

export const climateDataSettingsSchema = z.object({
  set: z.array(
    z.object({
      parameter: z.string(),
      timeframe: z.string().nullable(),
      unit: z.string(),
    }),
  ),
  achieved: z.array(
    z.object({
      parameter: z.string(),
      timeframe: z.string().nullable(),
      unit: z.string(),
    }),
  ),
});

export const fertilizationBinsSettingsSchema = z.object({
  id: z.string(),
  concentration: z.number(),
  name: z.string(),
  type: z.nativeEnum(FertilizationBinType),
  locations: z.array(z.string()),
});

export const cropLabelsSchema = z.object({
  fields: z.array(z.string()).optional(),
  size: z.string().nullable().optional(),
});

export const cropFormSettingsSchema = z.object({
  observation: z
    .object({
      rating: z.array(z.string()),
    })
    .optional(),
  measurement: z.array(z.string()).optional(),
  photoLabels: z.array(z.string()).optional(),
  wateringUnit: z.nativeEnum(WateringUnit).optional().nullable(),
  drainageUnit: z.nativeEnum(DrainageUnit).optional().nullable(),
  noteLabels: z.array(z.string()).optional(),
  selectedNoteLabels: z.array(z.string()).optional(),
  cultivationActivity: z.array(z.string()).optional(),
  wateringItems: z.array(z.nativeEnum(NoteWateringItems)).optional(),
});

export const settingsSchema = z.object({
  climateData: climateDataSettingsSchema,
  climateBoxes: climateBoxesSettingsSchema.optional(),
  fertilizationBins: z.array(fertilizationBinsSettingsSchema).optional(),
  pdfFields: z.array(z.string()).optional(),
  cropFields: z.array(z.nativeEnum(CropField)).optional(),
  cropLabels: cropLabelsSchema.optional(),
  weatherStats: z
    .array(
      z.object({
        name: z.string(),
        value: z.string(),
      }),
    )
    .optional(),
  cropForm: cropFormSettingsSchema.optional(),
  fillSurfaceForNewCrop: z.boolean().optional(),
  cropFullSurfaceAreaForTasks: z.boolean().optional(),
});

export const connectedCropSchema = z.object({
  id: z.string(),
  startDate: timestamp.nullable(),
  endDate: timestamp.nullable(),
});

export const connectedCropsSchema = z.record(z.array(connectedCropSchema));

export const sensorsConnectedCropsSchema = z.object({
  [SensorProvider.BLOCKBAX]: connectedCropsSchema.optional(),
  [SensorProvider.TENTACLES]: connectedCropsSchema.optional(),
});

export const sensorSettingsSchema = z.object({
  provider: z.nativeEnum(SensorProvider).optional(),
  connectedCrops: z
    .object({
      blockbax: connectedCropsSchema.optional(),
      tentacles: connectedCropsSchema.optional(),
    })
    .optional(),
  yAxisSettings: z
    .record(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
    )
    .optional(),
  yAxisSettingsGlobal: z.nativeEnum(YAxisSettingsGlobal).optional(),
});

const profileSchema = z.object({
  companyName: z.string().optional(),
  locations: z.array(locationsLevel0Schema),
  fertilizationMethod: z.string().optional(),
  waterSource: z.string().optional(),
  waterSample: z.string().optional(),
  bulbGrower: z.boolean().optional(),
  environment: environmentSchema.optional(),
  preferredLanguage: languageSchema.optional(),
  contactPerson: z.string().optional(),
  noteChecklists: z.array(noteChecklistSchema).optional(),
  labCustNumber: z.array(z.string()).optional(),
  substrates: z.array(z.any()).optional(),
  logo: z.string().optional().nullable(),
  location: z.any().optional(),
  cropLabels: z.array(z.string()).optional(),
  trays: z.array(z.string()).optional(),
  plugTypes: z.array(z.string()).optional(),
  substrateMixtures: z.array(z.string()).optional(),
  pdfWeatherOff: z.boolean().optional(),
  youngPlantSuppliers: z.array(z.string()).optional(),
  pdfWeekOff: z.boolean().optional(),
  companyLabels: companyLabelsSchema.optional(),
  settings: settingsSchema,
  // implement after db migration
  // tankMixes: z.array(tankMixSchema).optional(),
  tankMixes: z.array(z.any()).optional(),
  sensorSettings: sensorSettingsSchema,
});

export const companySchemaRead = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  created: timestamp.optional(),
  reportId: z.string().optional(),
  originalUserId: z.string().optional(),
  profile: profileSchema,
  protocols: z.array(documentReference(true)),
  crops: z.array(documentReference(true)),
});

export const companySchemaWrite = z.object({
  name: z.string(),
  slug: z.string(),
  created: timestamp.optional(),
  reportId: z.string().optional(),
  originalUserId: z.string().optional(),
  profile: profileSchema,
  protocols: z.array(documentReference(false)),
  crops: z.array(documentReference(false)),
});
type NoteChecklist = z.infer<typeof noteChecklistSchema>;

type LocationLevel0 = z.infer<typeof locationsLevel0Schema>;

type LocationLevel1 = z.infer<typeof locationLevel1Schema>;

type LocationLevel2 = z.infer<typeof locationLevel2Schema>;

type Weather = z.infer<typeof weatherSchema>;

type CompanySchema = z.infer<typeof companySchemaRead>;

type LocationMapItem<T extends number> = {
  level: number;
  parentId?: string;
  location: T extends 0
    ? LocationLevel0
    : T extends 1
      ? LocationLevel1
      : T extends 2
        ? LocationLevel2
        : never;
};

interface LocationMap {
  [key: string]: LocationMapItem<0 | 1 | 2>;
}

type ConnectedCrop = z.infer<typeof connectedCropSchema>;

type SubjectSensors = z.infer<typeof connectedCropsSchema>;

type SensorsConnectedCrops = z.infer<typeof sensorsConnectedCropsSchema>;

type SensorSettings = z.infer<typeof sensorSettingsSchema>;

type ClimateBoxesSettings = z.infer<typeof climateBoxesSettingsSchema>;

type ClimateDataSettings = z.infer<typeof climateDataSettingsSchema>;

type FertilizationBinsSettings = z.infer<
  typeof fertilizationBinsSettingsSchema
>;

// type CompanySchemaCreate = Omit<CompanySchema, "id" | "created"> & {
//   created: Date,
// };

type CompanySchemaCreate = z.infer<typeof companySchemaWrite>;

const inviteToken = z.object({
  id: z.string(),
  companyId: z.string(),
  companyName: z.string(),
  contactPerson: z.string().nullable(),
  created: timestamp.optional(),
  email: z.string(),
  preferredLanguage: z.enum(["en", "nl", "de", "fr"]),
  templateType: z.enum([
    "newCustomer",
    "substrateSampleService",
    "cropServiceLabAnalyses",
  ]),
  thirdPartyRequestId: z.string().nullable(),
  type: z.enum([UserType.ADMIN, UserType.USER]),
  roleId: z.string().nullable(),
  allowedLocations: z.array(allowedLocationSchema),
  usedUp: z.boolean().optional(),
});

type InviteToken = z.infer<typeof inviteToken>;

export type {
  CompanySchema,
  CompanySchemaCreate,
  Weather,
  LocationLevel0,
  LocationLevel1,
  LocationLevel2,
  LocationMap,
  LocationMapItem,
  ConnectedCrop,
  SubjectSensors,
  SensorSettings,
  ClimateBoxesSettings,
  ClimateDataSettings,
  FertilizationBinsSettings,
  NoteChecklist,
  SensorsConnectedCrops,
  InviteToken,
};
